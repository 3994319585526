<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark href="/course-list">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Back To Demo</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="row pt-12">
      <div class="col-12 text-center">
        <div class="demo-end">
          <h3 class="mb-2">Uh oh! That’s the end of your free training.</h3>
          <div>That sucks, just when you were getting into it.</div>
          <div>But it doesn’t have to be over.</div>
        </div>
        <!-- <v-btn
              class="my-3"
              href="https://gapsellingonlinetraining.com/"
              dark
              color="primary"
            >
              Click here to continue
            </v-btn> -->
        <p>Things only get better from here.</p>
        <div class="row pt-12">
          <v-col md="3" cols="12" v-for="plan in plans" :key="plan._id">
            <div class="plan-wrapper" @click="goToPlan(plan)">
              <v-row class="mx-auto">
                <v-col cols="12" class="mt-3">
                  <h5>&nbsp;</h5>
                  <div class="plan-card single ml-auto mr-md-1 mr-auto active">
                    <div class="top">
                      <div class="header-title mb-2">
                        {{ plan.name }}
                      </div>
                      <!-- <div class="header-title">{{ plan.description }}</div> -->
                      <div class="d-flex text-center justify-content-center cost">
                        <div class="amount">
                          {{ toCurrency(plan.paymentAmountTotal) }}
                        </div>

                        <div class="per">/{{ plan.paymentTerm }}</div>
                      </div>

                      <div class="pill">
                        <v-btn v-if="plan.planId == '4'" outlined color="primary mx-auto buy-now">
                          Contact Sales
                        </v-btn>
                        <v-btn v-if="plan.planId != '4'" outlined color="primary mx-auto buy-now">
                          Buy Now
                        </v-btn>
                      </div>
                      <p class="pt-5">
                        {{ plan.description }}
                      </p>
                    </div>
                    <div class="bottom">
                      <div class="benefit" v-for="seat in plan.includedSeats" v-bind:key="seat.name">
                        <span class="font-weight-bold mt-2">{{ seat.name }}</span>
                        <span>{{ " " + seat.details }}</span>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>

        <p class="mt-8">
          Not ready yet,
          <a href="https://www.asalesguy.com/gap-selling/?ref=gapsellingonlinetraining_demo" target="_blank">
            check out Gap Selling the book</a>
          and find out why it’s quickly become one of the best selling sales books in the world.
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import GET_PAYMENT_PLAN from "../graphql/GetPaymentPlan.gql";

export default {
  name: "Upgrade",
  data() {
    return {
      plans: null,
    };
  },
  computed: mapState(["auth"]),
  mounted() {
    this.getPaymentPlans();
  },
  methods: {
    async getPaymentPlans() {
      const plansResult = await this.$apollo.query({
        query: GET_PAYMENT_PLAN,
        fetchPolicy: "no-cache",
      });
      console.log(plansResult.data.paymentPlan);
      if (plansResult && plansResult.data && plansResult.data.paymentPlan) {
        this.plans = plansResult.data.paymentPlan.filter((x) => x.isSelectable);
      }

      this.yourPlan = this.plans.find(
        (x) =>
          x.stripePlans &&
          x.stripePlans.legth > 0 &&
          x.stripePlans.find((sp) => sp.id == this.auth.authUser.account.paymentPlanId)
      );

      let foundSelected = false;
      this.plans.forEach((x) => {
        x.canUpgradeTo = false;

        if (foundSelected) {
          x.canUpgradeTo = true;
        }
        if (x.stripePlans && x.stripePlans.length > 0) {
          x.isCurrent = x.stripePlans.find(
            (sp) => sp.id == this.auth.authUser.account.paymentPlanId
          );
          if (x.isCurrent) {
            foundSelected = true;
          }
        }
      });
    },
    goToPlan(plan) {
      if (plan.name == "LARGE COMPANY") {
        window.open(`https://salesgrowth.com/gap-selling-online-training-contact/`);
      } else {
        window.location.href = `/sign-up?plan=${plan.planId}`;
      }
    },
    toCurrency(currencyString) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(currencyString);
    },
  },
};
</script>

<style lang="scss">
// plan

.plan-header {
  width: 300px;
  color: #cb4f53;
}

// .plan-card {
//   cursor: pointer;
//   transition: 0.3s;
//   width: 100%;
//   border: 1px #707070 solid;
//   .top {
//     position: relative;

//     .pill {
//       padding: 10px 0;
//       position: absolute;
//       top: 215px;
//       left: 0px;
//       right: 0px;
//       text-align: center;
//       font-size: 16px;
//       font-weight: bold;
//       .buy-now {
//         width: 80%;
//         padding-left: 20px;
//         padding-right: 20px;
//       }
//     }
//     .header-title {
//       font-size: 22px;
//       text-align: center;
//       text-transform: capitalize;
//     }
//     .money-sign {
//       font-size: 18px;
//       padding-top: 10px;
//     }
//     .amount.strike {
//       font-size: 25px;
//       padding-top: 10px;
//       padding-right: 8px;
//     }

//     .amount {
//       font-size: 16px;
//     }
//     .per {
//       padding-top: 0px;
//     }
//   }
//   .plan-name {
//   }
//   font-size: 18px;
//   .description {
//     font-size: 14px;
//   }
//   .bottom {
//     padding: 25px;
//     min-height: 185px;
//   }
//   .seats {
//     font-style: 14px;
//   }
//   .includes {
//     font-style: 14px;
//   }

//   // &.standard {
//   //   .top {
//   //     background-color: #9eacaf;
//   //     .pill {
//   //       color: #9eacaf;
//   //     }
//   //   }
//   // }
// }

//</style>
