<template>
  <v-menu offset-y nudge-top="-5">
    <template v-slot:activator="{ on }">
      <v-badge
        color="green"
        :content="reminders && reminders.length > 0 ? reminders.length : 0"
        overlap
      >
        <v-btn small fab class="mb-0" v-on="on">
          <i class="far fa-bell"></i>
        </v-btn>
      </v-badge>
    </template>
    <v-card class="pa-4" v-if="!reminders">
      <div class="reminders pt-5">
        All caught up!
      </div>
    </v-card>
    <v-card class="pa-4" v-if="reminders">
      <div class="reminders pt-5">
        <div v-for="r in reminders" :key="r._id">
          <a :href="`/section/${r.sectionId}`"> {{ r.text }} </a>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import GET_REMINDERS from "../graphql/GetReminders.gql";
import { mapState } from "vuex";

export default {
  name: "Reminder",
  computed: mapState(["auth"]),
  data() {
    return {
      reminders: null
    };
  },
  mounted() {
    this.getProgressOverview();
  },
  methods: {
    async getProgressOverview() {
      const getRemindersResult = await this.$apollo
        .query({
          query: GET_REMINDERS,
          fetchPolicy: "no-cache",
          variables: {
            userId: this.auth.authUser._id,
            account: this.auth.authUser.account._id
          }
        })
        .catch(error => {
          this.getProgressOverviewReportError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      this.reminders =
        getRemindersResult.data.reminder && getRemindersResult.data.reminder.length > 0
          ? getRemindersResult.data.reminder
          : null;

      this.pieChartData = {
        labels: ["In Progress", "Complete", "Not Started"],
        datasets: [
          {
            label: "Dataset 1",
            backgroundColor: ["#89a8ff", "#89ff91", "#ff8989"],
            // borderColor: "#ff0000",
            borderWidth: 1,
            data: [
              this.progressOverviewReport ? this.progressOverviewReport.inProgress : 0,
              this.progressOverviewReport ? this.progressOverviewReport.completed : 0,
              this.progressOverviewReport ? this.progressOverviewReport.notStarted : 0
            ]
          }
        ]
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.reminders {
  z-index: 9999;
}
</style>
