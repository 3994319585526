const stateLocal = {
  authUser: null,
  bandwidth: "low",
  editMode: false,
};

// getters
const getters = {
  videoCensored: state => state.videoCensored,
  bandwidth: state => state.bandwidth,
  plans: state => state.plans
};

// actions
const actions = {
  async signup({ commit }, { authUser }) {
    localStorage.setItem("apollo-token", authUser.authToken);

    commit("setAuthUser", authUser);
  },
  async updateAccount({ state, commit }, { account }) {
    const authUser = state.authUser;
    authUser.account = account;
    commit("setAuthUser", authUser);
  },
  async updateVideoCensored({ commit }, { videoCensored }) {
    commit("setVideoCensored", videoCensored);
  },
  async updateBandwidth({ commit }, { bandwidth }) {
    commit("setBandwidth", bandwidth);
  },
  async updateEditMode({ commit }, { editMode }) {
    commit("setEditMode", editMode);
  },
  async signin({ commit }, { authUser }) {
    // set token
    if (authUser) {
      localStorage.setItem("apollo-token", authUser.authToken);
      commit("setAuthUser", authUser);
    } else {
      localStorage.removeItem("apollo-token", null);
      localStorage.removeItem("vuex", null);
      // commit("setAuthUser", null);
    }
  }
};

// mutations
const mutations = {
  setAuthUser(state, authUser) {
    state.authUser = authUser;
  },
  setVideoCensored(state, videoCensored) {
    state.videoCensored = videoCensored;
  },
  setBandwidth(state, bandwidth) {
    state.bandwidth = bandwidth;
  },
  setEditMode(state, editMode) {
    state.editMode = editMode;
  }
};

export default {
  namespaced: true,
  state: stateLocal,
  getters,
  actions,
  mutations
};
