import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#C7070E',
        secondary: '#0D0D0D',
        accent: '#6b6b6b',
        accent_text: '#6b6b6b',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        grey: colors.grey
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
