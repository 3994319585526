import { InMemoryCache } from "apollo-cache-inmemory";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

const cache = new InMemoryCache();

Vue.config.productionTip = false;

Vue.use(require("vue-moment"));

new Vue({
  vuetify,
  router,
  store,
  apolloProvider: createProvider({ cache }),
  i18n,
  render: h => h(App)
}).$mount("#app");
