<template>
  <v-app class="fill-height">
    <div>
      <div class="top-nav mb-5 mb-md-0 d-flex justify-space-between">
        <div class="logo-wrapper">
          <a href="/course-list-type">
            <v-img :src="require('./assets/logo.png')" class="my-0" contain height="80"></v-img>
          </a>
        </div>

        <!-- <div class="nav pt-7" id="nav" v-if="auth.authUser">
         <div id="navLinks">
            <router-link to="/">Home</router-link>
            <router-link to="/team">Team</router-link>
            <router-link
              v-if="auth.authUser && (auth.authUser.isAdmin || auth.authUser.isCompanyAdmin || auth.authUser.isTeamAdmin)"
              to="/reports"
            >Reports</router-link>
          </div>
        </div>-->

        <div id="user">
          <div class="row" v-if="!auth.authUser">
            <div class="pr-3 mr-3 mt-3">
              <a href="/sign-in">Sign In</a>
            </div>
          </div>

          <div v-if="auth.authUser" class="row mt-3 pt-3 mr-1 mr-md-3">
            <div v-if="auth.authUser && auth.authUser.account.paymentPlanId">
              <v-btn v-if="auth.authUser.account.paymentPlanId == 'demo'" href="/settings" color="primary"
                class="mt-2 mr-3 d-md-block d-none pt-1">
                Unlock Full Training
              </v-btn>

              <v-btn v-if="auth.authUser.account.paymentPlanId == 'demo'" href="/settings" color="primary" small fab
                class="mr-3 d-block d-md-none">
                <v-icon> mdi-lock-open-outline </v-icon>
              </v-btn>
            </div>
            <div v-if="auth && auth.authUser && auth.authUser.isAdmin && auth.editMode" class="mr-5 mb-2">
              <v-btn text color="primary" href="/admin/manage-sections">Manage Sections </v-btn>
            </div>
            <div v-if="auth && auth.authUser && auth.authUser.isAdmin" class="mr-5 mb-2">
              <v-switch @change="(value) => $store.dispatch('auth/updateEditMode', { editMode: value })" class="ma-1"
                label="Edit Mode" v-model="auth.editMode"></v-switch>
            </div>
            <div>
              <div class="lang-wrapper pt-2 pl-2 pb-2 mr-0 mr-md-2" v-if="$route.path != '/sign-up'">
                <v-select class="mr-md-3 mx-auto lang-select" v-model="lang" item-text="title" item-value="value"
                  :items="langs" label="Language" outlined hide-details dense @change="switchLang">
                  <template v-slot:selection="{ item }">
                    <div class="row px-2 justify-space-between">
                      <div class="pt-2">{{ item.title }}</div>

                      <div class="pt-1">
                        <v-img :src="`/icons/langs/${item.value}.png`" contain width="25px" height="25px">
                        </v-img>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item="{ item }">
                    <div class="row px-2 justify-space-between">
                      <div>{{ item.title }}</div>

                      <div>
                        <v-img :src="`/icons/langs/${item.value}.png`" contain width="25px" height="25px">
                        </v-img>
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>

            <div class="mr-3 reminders">
              <Reminder></Reminder>
            </div>

            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small fab class="mb-0" v-on="on">
                  <i class="fas fa-question"></i>
                </v-btn>
              </template>

              <v-card class="pa-4">
                <div class="reachout">
                  reach out to
                  <a href="mailto:support@salesgrowth.com" target="_blank">
                    support@salesgrowth.com
                  </a>
                  for help
                </div>
              </v-card>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small fab class="mb-0 ml-3" v-on="on">
                  <i class="far fa-user"></i>
                </v-btn>
              </template>

              <v-card class="px-10 py-5">
                <div class="row">
                  <div class="mr-2">{{ auth.authUser.firstName }}</div>

                  <div>{{ auth.authUser.lastName }}</div>
                </div>

                <div class="row">
                  <div>{{ auth.authUser.email }}</div>
                </div>
              </v-card>

              <v-list class="user-nav-links">
                <v-list-item class="text-center">
                  <v-btn class="mx-auto" target="_blank" text href="/settings">Settings</v-btn>
                </v-list-item>

                <v-list-item class="text-center">
                  <v-btn class="mx-auto" text href="/course-list">Home</v-btn>
                </v-list-item>

                <v-list-item class="text-center mt-5">
                  <v-btn class="mx-auto" color="grey" text @click="signout">
                    <small> Sign Out </small>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>

      <router-view />

      <!-- <Slide class="nav" id="mobileNav">
        <div class="col-12">
          <router-link to="/">Home</router-link>
        </div>
        <div class="col-12">
          <router-link to="/team">Team</router-link>
        </div>
        <div class="col-12">
          <router-link to="/reports" v-if="auth.authUser && auth.authUser.isAdmin">Reports</router-link>
        </div>
      </Slide>-->

      <div class="footer text-center">
        © {{ new Date().getFullYear() }} A Sales Guy LLC. All Rights Reserved.
        <a target="_blank" href="https://gapsellingonlinetraining.com/terms-and-conditions/">Terms and Conditions</a>
      </div>
    </div>

    <v-dialog v-model="dialogDemoIsOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
      <Upgrade></Upgrade>
    </v-dialog>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: "futura", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  margin-bottom: 100px;
  position: relative;
  background-color: #ffffff;
}

.lang-wrapper {
  background-color: #ffffff;
  margin-top: -12px;
  border-radius: 8px;
  position: absolute;
  top: 82px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.top-nav {
  background-color: #bfbfbf;
}

.v-application {
  .nav {
    text-align: center;

    a {
      font-weight: bold;
      padding: 4px 10px;
      color: #404040;
      text-decoration: none;

      &.router-link-exact-active {
        color: black;
      }
    }
  }
}

.footer {
  min-height: 170px;
  position: absolute;
  bottom: -310px;
  left: 0;
  right: 0;
  z-index: 0;
}

// shared styles
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}

.lang-select {
  width: 150px;
}

.section-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.1;
  color: black;
  margin-top: 30px;
  margin-bottom: 20px;
}

#mobileNav {
  display: block;
  z-index: 999;

  .bm-menu {
    background-color: white;
  }
}

.submit-btn {
  width: 250px;
}

#navLinks {
  display: none;
}

#mobileNav {
  .bm-burger-button {
    height: 18px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 15px;
  }
}

#mobileNav {
  .bm-menu {
    .bm-item-list>* {
      display: block;
      padding: 0;
    }
  }
}

.icon-lg {
  width: 70px;
}

.logo-wrapper {
  width: 150px;
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 460px) {
  .lang-wrapper {
    position: relative;
    top: 2px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .logo-wrapper {
    width: 200px;
  }

  #logo {
    position: absolute;
    top: 10px;
    left: 10px;
    margin: 0;
  }

  #mobileNav {
    display: none;
  }

  #navLinks {
    display: block;
  }
}

.home-link {
  background-color: var(--v-grey-lighten2);
  margin-bottom: -8px;
  // margin-top: 10px;
  border-top: 1px solid var(--v-grey-lighten1);
}

.user-nav-links .v-btn {
  width: 100%;
}
</style>

<script>
// @ is an alias to /src
// import { Slide } from "vue-burger-menu";
import { mapState } from "vuex";
import router from "./router";
import Upgrade from "@/components/Upgrade";
import Reminder from "@/components/Reminder";

export default {
  name: "app",
  computed: mapState(["auth"]),
  components: {
    Upgrade,
    Reminder,
  },
  data() {
    return {
      dialogDemoIsOpen: false,
      lang: this.$i18n.locale,
      lastPath: null,
      langs: [
        { title: "English", value: "en" },
        { title: "German", value: "de" },
      ],
    };
  },
  watch: {
    $route(route) {
      if (
        !this.auth.authUser &&
        route.path != "/sign-in" &&
        route.path != "/sign-up" &&
        route.path != "/" &&
        route.path != "/activate-user" &&
        route.path != "/reset-password-request" &&
        route.path != "/activate-user-team" &&
        route.path != "/reset-password" &&
        route.path != "/terms"
      ) {
        router.push({ path: `/sign-in?lasturl=${encodeURIComponent(location.href)}` });
      }
      try {
        if (this.auth && this.auth.authUser) {
          LogRocket.identify(this.auth.authUser.email, {
            name: this.auth.authUser.firstName + " " + this.auth.authUser.lastName,
            email: this.auth.authUser.email,
            subscriptionType: this.auth.authUser.account
              ? this.auth.authUser.account.paymentPlanId
              : "",
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      const { userLang } = this.$route.query;
      if (userLang && this.lang != userLang) {
        this.lang = userLang;
        this.switchLang();
      }
    },
  },
  mounted() {
    // const { userLang } = this.$route.query;
    // debugger;
    // if (userLang && this.lang != userLang) {
    //   this.lang = userLang;
    //   this.switchLang();
    // }
  },
  methods: {
    signout() {
      this.$store.dispatch("auth/signout");
      // router.push("/sign-in");
      window.location.href = "/sign-in";
    },
    switchLang() {
      // this.$locale; // root Vuei18n locale
      // this.$locale = "en"; // set root Vuei18n locale
      // or
      // this.$root.i18n.locale; // root Vuei18n locale
      // this.$root.i18n.locale = lang.value; // set root Vuei18n locale
      this.$forceUpdate();
      this.$i18n.locale = this.lang; // set root Vuei18n locale
      this.$root.$i18n.locale = this.lang;
      localStorage.setItem("lang", this.lang);

      let query = Object.assign({}, this.$route.query);
      delete query.userLang;
      this.$router.replace({ query });

      setTimeout(() => {
        window.location.reload();
      }, 10);
    },
  },
};
</script>
