import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import(/* webpackChunkName: "signin" */ "../views/SignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () => import(/* webpackChunkName: "signup" */ "../views/SignUp.vue"),
  },
  {
    path: "/reset-password-request",
    name: "resetPasswordRequest",
    component: () =>
      import(/* webpackChunkName: "resetPasswordRequest" */ "../views/ResetPasswordRequest.vue"),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import(/* webpackChunkName: "resetPassword" */ "../views/ResetPassword.vue"),
  },
  {
    path: "/activate-user",
    name: "ActivateUser",
    component: () => import(/* webpackChunkName: "ActivateUser" */ "../views/ActivateUser.vue"),
  },
  {
    path: "/activate-user-team",
    name: "ActivateUserTeam",
    component: () =>
      import(/* webpackChunkName: "ActivateUserTeam" */ "../views/ActivateUserTeam.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
  {
    path: "/",
    name: "course-list-type-default",
    component: () =>
      import(/* webpackChunkName: "CourseTypeListRoot" */ "../views/CourseTypeList.vue"),
  },
  {
    path: "/course-list-type",
    name: "course-list-type",
    component: () =>
      import(/* webpackChunkName: "course-list-type" */ "../views/CourseTypeList.vue"),
  },
  {
    path: "/course-list",
    name: "course-list",
    component: () => import(/* webpackChunkName: "course-list" */ "../views/CourseList.vue"),
  },
  {
    path: "/section/:sectionId",
    name: "section",
    component: () => import(/* webpackChunkName: "section" */ "../views/Section.vue"),
  },
  {
    path: "/lesson/:lessonId",
    name: "lesson",
    component: () => import(/* webpackChunkName: "lesson" */ "../views/Lesson.vue"),
  },
  {
    path: "/admin/manage-sections",
    name: "manage-sections",
    component: () => import(/* webpackChunkName: "managesections" */ "../views/ManageSections.vue"),
  },
  // {
  //   path: '/reports/:reportType',
  //   name: 'reports',
  //   component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
  // },
  {
    path: "/terms",
    name: "terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
